[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Kim-Portfolio' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-BallonContact:before {
  content: "\e903";
}

.icon-BallonKim:before {
  content: "\e904";
}

.icon-BallonProjecten:before {
  content: "\e905";
}

.icon-BallonHome:before {
  content: "\e906";
}

.icon-logo_fb:before {
  content: "\e901";
  color: #fff;
}

.icon-logo_ig:before {
  content: "\e902";
  color: #fff;
}

.icon-logo_icon:before {
  content: "\e900";
}

.icon-logo_icon2:before {
  content: "\e900";
}

.icon-Linkedin:before {
  content: "\e908";
  color: #fff;
  font-size: 25px;
}

.icon-BallonMenuContact4:before {
  content: "\e907";
}