html {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Kim-Portfolio';
  src: url('./fonts/Kim-Portfolio.eot?ge87on');
  src: url('./fonts/Kim-Portfolio.eot?ge87on#iefix') format('embedded-opentype'),
    url('./fonts/Kim-Portfolio.ttf?ge87on') format('truetype'),
    url('./fonts/Kim-Portfolio.woff?ge87on') format('font-woff'),
    url('./fonts/Kim-Portfolio.svg?ge87on#Kim-Portfolio') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaLight';
  src: url('./fonts/Futura\ Light\ font.ttf');
  src: local('Futura Light'),
    format('opentype');
}

/*bold version*/
@font-face {
  font-family: 'FuturaHeavy';
  src: url('./fonts/Futura\ Heavy\ font.ttf');
  src: local('Futura Heavy'),
    format('opentype');
}

/*futura medium bluetooth*/
@font-face {
  font-family: 'Futuramediumbt';
  src: url('./fonts/futura\ medium\ bt.ttf');
  src: local('Futura Medium'),
    format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  text-decoration: none;
}

.container {
  padding: 0 1rem;
}

.btn {
  display: inline-block;
  border: none;
  background: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666;
}